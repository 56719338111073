import { Account } from './account';
import { MarketRegion, Permissions } from './enums/enums';
import ApplicationContext from './how/application-context';
import { CachedSecurity } from './quote/cached-security';

// For embedder region permission check
export const hasRegionAccess = (cachedData: CachedSecurity) => {
  const regionAccessFromConfig = ApplicationContext.configuration?.additionalData?.regionAccess;
  if (!regionAccessFromConfig) return false;

  const accessibleRegions = regionAccessFromConfig.split(',');
  return accessibleRegions.includes(cachedData.region);
};

// if AAPL is passed, allowed symbol to load only if US region access
// if AAPL.(US_EXCHANGE), allowed symbol to load only if US region access
// if AAPL.(CA_EXCHANGE), and if have US region access, deny to load respective symbol

// Get the region by account permissions
const getRegionByAccount = (account?: Account): string[] => {
  if (account && account.isUSAllowed && account.isCanadaAllowed) return [Permissions.US, Permissions.CA];
  if (account && account.isUSAllowed) return [Permissions.US];
  if (account && account.isCanadaAllowed) return [Permissions.CA];
  return null; // Default to US
};
// Find symbol with accessible exchange code based on region permissions
export const getSymbolWithExchangeCode = (cachedData: CachedSecurity[], account?: Account) => {
  const regionAccessFromConfig = getRegionByAccount(account);
  if (!regionAccessFromConfig) {
    return;
  }

  const cachedDataByRegion = cachedData.filter((c) => regionAccessFromConfig.includes(c.region));
  if (cachedDataByRegion.length === 0) return null;

  return cachedDataByRegion.find((c) => c.region === Permissions.US) || cachedDataByRegion[0];
};

export const splitSymbol = (symbol: string): [newSymbol: string, exchangeCode: string] => {
  const [newSymbol, exchangeCode] = symbol.toUpperCase()?.split(/\.(?=[^.]*$)/);
  return [newSymbol, exchangeCode];
};

export const getRegionBySymbol = (symbol: string, exchangeCode: string, cachedSecurity: CachedSecurity[]) => {
  if (!cachedSecurity) {
    return null;
  }
  return cachedSecurity.find((c) => c.symbol === symbol && c.exchangeCode === exchangeCode)?.region;
};

/**
 * !important
 * Used in the TradeTicket and ts-authenticate-widget (Tradestation)
 * to determine the region based on the exchange
 */

export const getRegionByExchange = (exchange: string) => {
  if (exchange === 'XTSE') {
    return 'CA' as MarketRegion;
  }
  return 'US' as MarketRegion;
};

export const getRegionAndFormattedSymbol = (symbol: string) => {
  const [symbolWithoutExchange, exchange] = splitSymbol(symbol);

  // Determine region based on exchange
  const region = getRegionByExchange(exchange);

  // Format symbol based on region
  const formattedSymbolByRegion = region === MarketRegion.TMX ? symbol : symbolWithoutExchange;

  return { region, formattedSymbolByRegion, exchange, symbolWithoutExchange };
};
