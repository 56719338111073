import { Button, Grid, Typography, useTheme } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { OPBoldTypograpghy } from '../styled';
import { ITradeProps } from './models/ts-helpers';
import { ClosePositionWidget } from './order-ticket/close-position-widget';
import { tsOrderTicketState, tsSelectedTabState } from './states/trade-station-states';
import {
  confirmOrderTicketState,
  tsBidMidAskValueState,
  tsLimitPriceState,
  tsOrderPlacementState,
  tsOrdetTicketPositionState,
  tsTradeOrderPropsState,
} from './states/ts-ticket-states';
import { PositionRow } from './ts-positions-widget';

interface Props {
  disabled?: boolean;
  selectedPositions?: PositionRow[];
}

export const TsPositionActionMenu: React.FC<Props> = ({ disabled, selectedPositions }: Props) => {
  const theme = useTheme();
  const setTsTradeOrderProps = useSetRecoilState(tsTradeOrderPropsState);
  const setTrade = useSetRecoilState(tsOrderPlacementState);
  const setTSSelectedTab = useSetRecoilState(tsSelectedTabState);
  const resetTsOrderTicketPositions = useResetRecoilState(tsOrdetTicketPositionState);
  const resetBidMidAsk = useResetRecoilState(tsBidMidAskValueState);
  const resetConfirmOrderTicket = useResetRecoilState(confirmOrderTicketState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setOrderTicketValue = useSetRecoilState(tsOrderTicketState);
  const resetTSLimitPrice = useResetRecoilState(tsLimitPriceState);

  const tsTradeProps = (action: 'trade' | 'closeAtMarket' | 'roll') => {
    const positions: ITradeProps[] = selectedPositions.map((pos) => {
      const position: ITradeProps = {
        underlyingSymbol: pos.underlyingSymbol,
        qty: pos.qty,
        symbol: pos.symbol,
        accountID: pos.accountId,
        longShort: pos.longShort,
        action,
        initiator: 'positions',
      };
      return position;
    });
    return positions;
  };

  const onTrade = async () => {
    if (selectedPositions.length > 4) {
      setNotifications([{ type: 'error', content: `Only 4 legs can be added to a spread`, isTradeStation: true }]);
      return;
    }
    const totalSecurityPos = selectedPositions.filter((s) => s.assetType === 'STOCK');
    if (totalSecurityPos.length > 1) {
      setNotifications([
        {
          type: 'error',
          content: `Cannot trade for more than 1 security position at a time`,
          isTradeStation: true,
        },
      ]);
      return;
    }
    const positions = tsTradeProps('trade');
    setTsTradeOrderProps(positions);
    setTrade(true);
    setTSSelectedTab('orderTicket');
    resetConfirmOrderTicket();
  };

  const renderStickyActions = () => {
    const positionTexts = selectedPositions.map((pos) => {
      return `${pos.qty} ${pos.side} position of ${pos.symbol}`;
    });
    return (
      <div style={{ position: 'sticky', bottom: 0, width: '100%', zIndex: 1000 }}>
        <Grid
          item
          xs={12}
          container
          p={1}
          style={{
            backgroundColor: theme.palette.mode === 'light' ? '#D9D9D9' : '#343A3D',
            right: 5,
          }}>
          <Grid item xs={4}>
            <OPBoldTypograpghy>Selection:</OPBoldTypograpghy>
            <Typography>Position: {positionTexts.join(', ')}</Typography>
          </Grid>
          <Grid item xs={8} container justifyContent="flex-end" columnSpacing={1} alignItems="center">
            <ClosePositionWidget tsTradeProps={tsTradeProps('closeAtMarket')} />
            {/* <Grid item xs={2}>
              <Button
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  ':hover': {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                fullWidth
                variant="contained">
                <OPBoldTypograpghy>Roll Position</OPBoldTypograpghy>
              </Button>
            </Grid> */}
            <Grid item xs={2}>
              <Button
                sx={{
                  backgroundColor: theme.palette.success.main,
                  ':hover': {
                    backgroundColor: theme.palette.success.main,
                  },
                }}
                fullWidth
                variant="contained"
                onClick={() => {
                  resetTsOrderTicketPositions();
                  resetBidMidAsk();
                  resetTSLimitPrice();
                  onTrade();
                  setOrderTicketValue('default');
                }}>
                <OPBoldTypograpghy>Trade</OPBoldTypograpghy>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return renderStickyActions();
};
