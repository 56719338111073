import { Filters, PortfolioCombinationAccount } from '@op/shared/src/models';
import { IAlertsByAccounts } from '@op/shared/src/models/portfolio/alert-filter-model';
import loadash from 'lodash';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  configurationState,
  guardRecoilDefaultValue,
  portfolioAccountsDataState,
  portfolioCombinationsOfSelectedAccountsState,
  portfolioSelectedAccountIdsState,
} from '.';
import { PortfolioAlert } from '../hubs/portfolio-alert';
import { PowerhouseProAlertModel } from '../hubs/powerhouse-pro-alert-model';
import { AlertType } from '../models/enums/enums';
import ApplicationContext from '../models/how/application-context';

export const portfolioCombinationAlertOrNotificationSelectedState = atom<
  | {
      alertType: AlertType;
      combinationHash: string;
    }
  | undefined
>({
  key: 'portfolioCombinationAlertOrNotificationSelectedStateKey',
  default: undefined,
});

export const portfolioAlertByAccountCachedState = atomFamily<PowerhouseProAlertModel | undefined, number>({
  key: 'portfolioAlertsByAccountCachedStateKey',
  default: undefined,
});

// This is used for declaring the types of filters EX: alert,notifications
export const filtersPortfoliosState = atom({
  key: 'filtersPortfoliosStateKey',
  default: Filters.portfolioDefaultFilters,
});

export const selectedAlertFilterState = atom({
  key: 'selectedAlertFilterStateKey',
  default: Filters.portfolioDefaultFilters.data.find((f) => f.name === 'alert'),
});

export const selectedNotificationFilterState = atom({
  key: 'selectedNotificationFilterStateKey',
  default: Filters.portfolioDefaultFilters.data.find((f) => f.name === 'notification'),
});

export const portfolioAlertsState = selector<PowerhouseProAlertModel[] | undefined>({
  key: 'portfolioAlertsStateKey',
  get: () => {
    throw new Error('Not implemented');
  },
  set: ({ set, get }, newAlerts) => {
    if (!newAlerts || guardRecoilDefaultValue(newAlerts)) {
      return;
    }

    const selectedAccountIds = get(portfolioSelectedAccountIdsState);
    const accounts = get(portfolioAccountsDataState);
    if (!selectedAccountIds || selectedAccountIds.length === 0 || !accounts || accounts.length === 0) {
      return undefined;
    }
    const ithacaAlerts = processAndSetIthacaNotifcations(selectedAccountIds, accounts);
    //  Combine both arrays into one by merging the objects with the same accountId
    const mergedArray = [
      /**
       * We use map() to iterate over firstArray and for each item, use find() to check if there’s a matching object in secondArray based on accountId.
       * If a match is found, we merge the alerts arrays and pick the most recent processingOn date.
       */
      ...newAlerts.map((newAlert) => {
        const ithacaAlert = ithacaAlerts.find((o) => o.accountId === newAlert.accountId);
        if (ithacaAlert) {
          return {
            accountId: newAlert.accountId,
            alerts: [...newAlert.alerts, ...ithacaAlert.alerts],
            processingOn:
              new Date(newAlert.processingOn) > new Date(ithacaAlert.processingOn)
                ? newAlert.processingOn
                : ithacaAlert.processingOn,
            processingStatus: newAlert.processingStatus, // or secondObj.processingStatus, depending on which you prefer
          };
        }
        return newAlert;
      }),
      /**
       * 	We use filter() to add objects from secondArray that don’t exist in firstArray (based on accountId).
       */
      ...ithacaAlerts.filter((secondObj) => !newAlerts.some((firstObj) => firstObj.accountId === secondObj.accountId)),
    ];
    for (let alert of mergedArray) {
      set(portfolioAlertByAccountCachedState(alert.accountId), alert);
    }
  },
});

// Function to process and update Recoil state
export const processAndSetIthacaNotifcations = (
  selectedAccountIds: number[],
  accounts: PortfolioCombinationAccount[],
) => {
  const selectedAccounts: PortfolioCombinationAccount[] = [];
  for (let id of selectedAccountIds) {
    const matchingAccounts = accounts.filter((a) => a.portfolioId === id);
    selectedAccounts.push(...matchingAccounts);
  }
  const uniqueCombinations = new Map<string, { hash: string; accountId: number; sentiment: string | null }>();

  for (const account of selectedAccounts) {
    account?.portfolioCombinations
      .filter((c) => {
        return c.tradeIdeaSentiment !== null;
      }) // Filter based on sentiment
      .forEach((combination) => {
        if (combination.hash) {
          uniqueCombinations.set(combination.hash, {
            hash: combination.hash,
            accountId: account.portfolioId,
            sentiment: combination.tradeIdeaSentiment,
          });
        }
      });
  }
  const filteredCombinations = Array.from(uniqueCombinations.values());
  const alertsByAccount: Record<number, PowerhouseProAlertModel> = {};
  // Build alerts by account
  filteredCombinations.forEach(({ hash, accountId, sentiment }) => {
    // Initialize the model for the account if it doesn't exist
    if (!alertsByAccount[accountId]) {
      alertsByAccount[accountId] = {
        accountId,
        processingStatus: 'NeedToBeProcessed',
        processingOn: new Date(),
        alerts: [],
      };
    }

    // Add a new alert to the model
    alertsByAccount[accountId].alerts.push({
      type: 'Notification' as AlertType,
      adjustmentType: undefined, // Set if applicable
      key: 'Ithaca',
      portfolioCombinationHash: hash,
      additionalAlertData: { sentiment: sentiment }, // Include hash as sentiment from the array
    });
  });
  return Object.values(alertsByAccount);
};

const portfolioAlertsByAccountsState = selector<PortfolioAlert[] | undefined>({
  key: 'portfolioAlertsByAccountsStateKey',
  get: ({ get }) => {
    const selectedAccountIds = get(portfolioSelectedAccountIdsState);
    const accounts = get(portfolioAccountsDataState);
    if (!selectedAccountIds || selectedAccountIds.length === 0 || !accounts || accounts.length === 0) {
      return undefined;
    }
    let alertsOnCombination: PortfolioAlert[] = [];
    for (let id of selectedAccountIds) {
      const alertsAndNotificationsOfAccount = get(portfolioAlertByAccountCachedState(id));
      if (!alertsAndNotificationsOfAccount || alertsAndNotificationsOfAccount.alerts.length === 0) {
        continue;
      }
      const account = accounts.find((a) => a.portfolioId === id);
      if (!account) {
        continue;
      }
      const alert = alertsAndNotificationsOfAccount.alerts.filter((alert) =>
        account.portfolioCombinations.some(
          (comb) => alert.type === AlertType.ALERT && alert.portfolioCombinationHash === comb.hash,
        ),
      );
      alertsOnCombination = alertsOnCombination.concat(alert);
    }
    return alertsOnCombination;
  },
});

/**
 * @deprecated DO NOT USE IT. REMOVE IT.
 * Changing number to PortfolioALert
 */
export const portfolioAlertsByAccountCountState = selector<number>({
  key: 'portfolioAlertsByAccountCountStateKey',
  get: ({ get }) => {
    const notifications = get(portfolioAlertsByAccountsState);
    return notifications?.length || 0;
  },
});

export const alertsByAccountsState = selector<IAlertsByAccounts | undefined>({
  key: 'alertsByAccountsStateKey',
  get: ({ get }) => {
    const alerts = get(portfolioAlertsByAccountsState);
    const configuration = get(configurationState);
    const combinationEntitiesOfSelectedAccounts = get(portfolioCombinationsOfSelectedAccountsState);
    if (!alerts || !configuration || !configuration.portfolioAlertResources || !combinationEntitiesOfSelectedAccounts) {
      return;
    }
    const alertsByKey = alerts.map((a) => a.key);
    const alertsWithCount = loadash.map(loadash.countBy(alertsByKey), (value, key) => {
      let heading = key;
      let hash: string = '';
      alerts.map((a) => {
        if (a.key === key) {
          hash += a.portfolioCombinationHash.trim() + ',';
        }
      });
      configuration.portfolioAlertResources.map((r) => {
        if (r.key === key) {
          heading = r.heading;
        }
      });
      return { key: key, name: heading || key, value: hash, count: value };
    });
    return { alert: alertsWithCount, totalCount: alerts.length };
  },
});

export const portfolioAlertsByAccountAndCombinationState = selectorFamily<PortfolioAlert[] | undefined, string>({
  key: 'portfolioAlertsByAccountAndCombinationStateKey',
  get:
    (portfolioCombinationHash: string) =>
    ({ get }) => {
      const alerts = get(portfolioAlertsByAccountsState);
      if (!alerts) {
        return undefined;
      }
      return alerts.filter((a) => a.portfolioCombinationHash === portfolioCombinationHash);
    },
});

const portfolioNotificationsByAccountState = selector<PortfolioAlert[] | undefined>({
  key: 'portfolioNotificationsByAccountStateKey',
  get: ({ get }) => {
    const selectedAccountIds = get(portfolioSelectedAccountIdsState);
    const accounts = get(portfolioAccountsDataState);
    if (!selectedAccountIds || selectedAccountIds.length === 0 || !accounts || accounts.length === 0) {
      return undefined;
    }
    let notificationsOnCombination: PortfolioAlert[] = [];
    for (let id of selectedAccountIds) {
      const alertsAndNotificationsOfAccount = get(portfolioAlertByAccountCachedState(id));
      if (!alertsAndNotificationsOfAccount || alertsAndNotificationsOfAccount.alerts.length === 0) {
        continue;
      }
      const account = accounts.find((a) => a.portfolioId === id);
      if (!account) {
        continue;
      }
      const alert = alertsAndNotificationsOfAccount.alerts.filter((alert) =>
        account.portfolioCombinations.some(
          (comb) => alert.type === AlertType.NOTIFICATION && alert.portfolioCombinationHash === comb.hash,
        ),
      );
      notificationsOnCombination = notificationsOnCombination.concat(alert);
    }
    return notificationsOnCombination;
  },
});

/**
 * @deprecated DO NOT USE IT. REMOVE IT.
 * Changing number to PortfolioALert
 */
export const portfolioNotificationsByAccountCountState = selector<number>({
  key: 'portfolioNotificationsByAccountCountStateKey',
  get: ({ get }) => {
    const notifications = get(portfolioNotificationsByAccountState);
    return notifications?.length || 0;
  },
});

//changed
export const notificationByAccountsState = selector<IAlertsByAccounts | undefined>({
  key: 'notificationByAccountsStateKey',
  get: ({ get }) => {
    const notifications = get(portfolioNotificationsByAccountState);
    const configuration = get(configurationState);
    const combinationEntitiesOfSelectedAccounts = get(portfolioCombinationsOfSelectedAccountsState);
    if (
      !notifications ||
      !configuration ||
      !configuration.portfolioAlertResources ||
      !combinationEntitiesOfSelectedAccounts
    ) {
      return;
    }
    const notificationsByKey = notifications.map((a) => a.key);
    const notificationWithCount = loadash.map(loadash.countBy(notificationsByKey), (value, key) => {
      let heading = key;
      let hash: string = '';
      notifications.map((a) => {
        if (a.key === key) {
          hash += a.portfolioCombinationHash.trim() + ',';
        }
      });
      configuration.portfolioAlertResources.map((r) => {
        if (r.key === key) {
          heading = r.heading;
        }
      });
      return { key: key, name: heading || key, value: hash, count: value };
    });
    return { alert: notificationWithCount, totalCount: notifications.length };
  },
});

export const portfolioNotificationsByAccountAndCombinationState = selectorFamily<PortfolioAlert[] | undefined, string>({
  key: 'portfolioNotificationsByAccountAndCombinationStateKey',
  get:
    (portfolioCombinationHash: string) =>
    ({ get }) => {
      const notifications = get(portfolioNotificationsByAccountState);
      if (!notifications) {
        return undefined;
      }
      return notifications.filter((a) => a.portfolioCombinationHash === portfolioCombinationHash);
    },
});

export const subscribePortfolioAlerts = async (accountIds: (number | string)[] | undefined) => {
  if (!accountIds || accountIds.length === 0) {
    return;
  }
  const powerhouseProHub = ApplicationContext.powerhouseProHub;
  if (!powerhouseProHub) {
    return undefined;
  }
  await powerhouseProHub.portfolioAlertSubscription.subscribe(accountIds);
};

export const unsubscribePortfolioAlerts = async (accountIds: (number | string)[] | undefined) => {
  if (!accountIds || accountIds.length === 0) {
    return;
  }
  const powerhouseProHub = ApplicationContext.powerhouseProHub;
  if (!powerhouseProHub) {
    return undefined;
  }
  await powerhouseProHub.portfolioAlertSubscription.unsubscribe([accountIds]);
};
