import { atom, atomFamily, DefaultValue, selector } from 'recoil';
import { splitSymbol } from '../../models/common-helper';
import { accountState, configurationState, howWhyRanksDataState } from '..';
import { Account, IConfiguration } from '../../models';
import { ExchangeCode, MarketRegion, Permissions, tradeSimulatorOperationType } from '../../models/enums/enums';

export const guardRecoilDefaultValue = (candidate: any): candidate is DefaultValue => {
  if (candidate instanceof DefaultValue) return true;
  return false;
};

/*
 * Previouly selected state for trade, income and portfolio management tabs.
 * The string keys are same as used in `viewState`. We can have an enum for these.
 * NOTE:
 * In case of portfolio, multiple combinations of a portfolio can have same symbol.
 * For example: client can have portfolio having (a) AAPL (200 shares) (b) AAPL (Long Call Vertical)
 * Hence combination hash is unique and used to track previously selected combination instead of symbol.
 */
export const previousSelectedSymbolState = atomFamily<string | undefined, 'trade' | 'income' | 'portfolioManagement'>({
  key: 'previousSelectedSymbolStateKey',
  default: undefined,
});

const getFallbackSymbol = (account: Account, configuration: IConfiguration, storageSymbol?: string) => {
  if (!storageSymbol) {
    return configuration.marketIndexQuoteSymbol.toUpperCase();
  }
  const [newSymbol, exchangeCode] = splitSymbol(storageSymbol.toUpperCase());
  const region = getMarketRegion(exchangeCode);
  const permissionAvailable = account.securityModel.hasPermission(region.toString() as Permissions);
  if (permissionAvailable) {
    return storageSymbol;
  }
  return configuration.marketIndexQuoteSymbol.toUpperCase();
};

export const getMarketRegion = (value: string) => {
  switch (value) {
    case ExchangeCode.US:
      return MarketRegion.US;
    case ExchangeCode.XTSE:
      return MarketRegion.TMX;
    default:
      return MarketRegion.US;
  }
};

export const selectedSymbolState = selector({
  key: 'selectedSymbolStateKey',
  get: ({ get }) => {
    const configuration = get(configurationState);
    const howWhy = get(howWhyRanksDataState);
    const account = get(accountState);
    if (!account) {
      return;
    }
    if (!configuration) {
      return 'SPY';
    }
    if (howWhy?.symbol) {
      return howWhy.symbol;
    }
    const storageKey = configuration.isEmbeddingPlatform ? 'embedSelectedSymbolStateKey' : 'selectedSymbolStateKey';
    const storageSymbol = window.localStorage && window.localStorage.getItem(storageKey);
    const allowedSecurities = configuration.additionalData?.allowedSecurities?.split(',').map((s) => s.toUpperCase());
    if ((!allowedSecurities || allowedSecurities.length === 0) && storageSymbol) {
      return getFallbackSymbol(account, configuration, storageSymbol);
      // return storageSymbol;
    }
    const isExists = allowedSecurities?.find((s) => s === storageSymbol);
    if (storageSymbol && isExists) {
      return storageSymbol;
    }
    const defaultSymbol = allowedSecurities?.find((s) => s.trim().toUpperCase() === 'XND');
    if (defaultSymbol) {
      return defaultSymbol;
    }
    const first = allowedSecurities && allowedSecurities[0];
    if (first && first.trim() !== '') {
      return first.toUpperCase();
    }
    return getFallbackSymbol(account, configuration);
    // return configuration?.marketIndexQuoteSymbol.toUpperCase() || 'SPY';
  },
});

export const tradeSimulatorOperationTypeState = atom({
  key: 'tradeSimulatorOperationTypeStateKey',
  default: tradeSimulatorOperationType.PLSimulator,
});

export const flippedState = atom({
  key: 'flippedStateKey',
  default: false,
});
