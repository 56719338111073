import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Lightbox } from '@op/shared/src/models';
import { ApiService, ProfileDetailsService } from '@op/shared/src/services';
import { accountState } from '@op/shared/src/states/account-state';
import {
  lightboxDataState,
  lightboxNotificationClick,
  lightboxSelectorState,
  lightboxState,
  unreadLightboxState,
} from '@op/shared/src/states/lightbox-open-close';
import { configurationState } from '@op/shared/src/states';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ScrollableBox } from '../styled/options-play-components';

export const LbButton = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  background: '#7AC143',
  border: '#7AC143',
  borderRadius: theme.shape.borderRadius,
  color: '#FFF',
  fontWeight: 500,
  textDecoration: 'none',
  textAlign: 'center',
  padding: '10px 20px',
}));

// TODO : Light Box widget needs to be done as same as Disclaimer widget
export const LightBoxWidget: React.FC = () => {
  const [closeLightBox, setCloseLightBox] = useRecoilState(lightboxState);
  const setLightboxData = useSetRecoilState(lightboxDataState);
  const setUnreadLightboxCount = useSetRecoilState(unreadLightboxState);
  const lightboxData = useRecoilValue(lightboxSelectorState);
  const isLightboxNotificationClick = useRecoilValue(lightboxNotificationClick);
  const configuration = useRecoilValue(configurationState);
  const account = useRecoilValue(accountState);
  const [prevTitle, setPrevTitle] = React.useState('');
  const [nextTitle, setNextTitle] = React.useState('');
  const theme = useTheme();
  const [isChecked, setIsChecked] = React.useState(true);
  const [clonedLightboxes, setClonedLightboxes] = React.useState<any[]>([]);

  const lightboxes = lightboxData;
  const link = `${ApiService.instance.API_ENDPOINT}/storedFile/`;

  var regexToRemoveHeaders = new RegExp(/<h([1-6].*?)>(.*?)<\/h([1-6])>/);

  let allLightboxes: Lightbox[] = [];
  let unReadLightboxes: Lightbox[] = [];
  let timeZoneAbbrevation: string | undefined;

  useEffect(() => {
    setLightboxData(configuration?.lightbox);
    checkOnLoad();
  }, [configuration]);

  const checkOnLoad = () => {
    if (!unReadLightboxes) {
      return null;
    }
    if (isLightboxNotificationClick === false) {
      if (unReadLightboxes.length > 0) {
        unReadLightboxes.forEach((element, i) => {
          const model = new Lightbox();
          model.isLightboxAccepted = false;
          model.categoryString = element.categoryString;
          model.content = element.content;
          model.id = element.id;
          model.name = element.name;
          model.eventEndDateTime = element.eventEndDateTime;
          model.eventStartDateTime = getDateTimeInFormat(element.eventStartDateTime);
          model.url = element.url;
          model.webinarLabel = element.webinarLabel;
          model.webinarLink = element.webinarLink;
          model.isRead = element.isLightboxAccepted;
          let lb = Lightbox.fromJson(model);
          clonedLightboxes.push(lb);
        });
        if (clonedLightboxes.length > 0) {
          clonedLightboxes[0].isLightboxAccepted = true;
          setClonedLightboxes(clonedLightboxes);
        }
      }
    } else {
      allLightboxes.forEach((element, i) => {
        const model = new Lightbox();
        model.isLightboxAccepted = false;
        model.categoryString = element.categoryString;
        model.content = element.content;
        model.id = element.id;
        model.name = element.name;
        model.eventEndDateTime = element.eventEndDateTime;
        model.eventStartDateTime = getDateTimeInFormat(element.eventStartDateTime);
        model.url = element.url;
        model.webinarLabel = element.webinarLabel;
        model.webinarLink = element.webinarLink;
        model.isRead = element.isLightboxAccepted;
        let lb = Lightbox.fromJson(model);
        clonedLightboxes.push(lb);
      });
      if (clonedLightboxes.length > 0) {
        clonedLightboxes[0].isLightboxAccepted = true;
        setClonedLightboxes(clonedLightboxes);
      }
    }
    if (clonedLightboxes.length === 0) {
      setCloseLightBox(false);
    } else {
      setCloseLightBox(true);
    }
    if (clonedLightboxes.length > 0) {
      let prevTitle = clonedLightboxes.length === 1 ? <></> : clonedLightboxes[clonedLightboxes.length - 1].name;
      let nextTitle = clonedLightboxes.length === 1 ? <></> : clonedLightboxes[1].name;
      setPrevTitle(formatLightboxText(prevTitle));
      setNextTitle(formatLightboxText(nextTitle));
    }
  };

  if (!account || !lightboxData || !configuration || !account.canAccessApplication) {
    return null;
  }

  timeZoneAbbrevation = configuration.marketWorkTimeInformation.marketTimeZoneAbbrevation;

  if (lightboxes != null && lightboxes !== undefined) {
    allLightboxes = lightboxes as unknown as Lightbox[];
    unReadLightboxes = lightboxes && lightboxes.filter((a) => a.isLightboxAccepted === false);
  } else {
    allLightboxes = [];
    unReadLightboxes = [];
  }

  const handleClose = async (): Promise<void> => {
    setCloseLightBox(false);
    const selected: number[] = [];
    if (clonedLightboxes.length > 0) {
      clonedLightboxes.forEach((l) => {
        if (l.isLightboxAccepted && !l.isRead) {
          selected.push(l.id);
        }
      });
    }
    if (selected.length > 0) {
      const editResponse = await ProfileDetailsService.instance.AcceptedLightbox(selected);
      let acceptedLightboxIds =
        editResponse?.additionalSerializedData.map((i: any) => {
          return i.lightboxId;
        }) || [];
      // Remove read item from unReadLightboxes array
      selected.forEach((element) => {
        const removeIndex = unReadLightboxes.findIndex(function (o) {
          return o.id === element;
        });
        if (removeIndex >= 0) {
          unReadLightboxes.splice(removeIndex, 1);
        }
      });
      setUnreadLightboxCount(unReadLightboxes.length);
      const clonedLightbox: Lightbox[] = [];
      allLightboxes.forEach(function (element) {
        const model = new Lightbox();
        if (acceptedLightboxIds.includes(element.id)) {
          model.isLightboxAccepted = true;
        } else {
          model.isLightboxAccepted = false;
        }
        model.categoryString = element.categoryString;
        model.content = element.content;
        model.id = element.id;
        model.name = element.name;
        model.eventEndDateTime = element.eventEndDateTime;
        model.eventStartDateTime = element.eventStartDateTime;
        model.url = element.url;
        model.webinarLabel = element.webinarLabel;
        model.webinarLink = element.webinarLink;
        let lb = Lightbox.fromJson(model);
        clonedLightbox.push(lb);
      });
      allLightboxes = Array.from(clonedLightbox);
      // Sort un-read lightboxes first
      allLightboxes.sort(function (l) {
        return l.isLightboxAccepted ? 1 : -1;
      });
      setLightboxData(allLightboxes);
    }
  };

  const formatLightboxText = (lightboxName: string) => {
    return lightboxName.length > 15 ? lightboxName.substring(0, 15) + '...' : lightboxName;
  };

  const carouselChange = (currentIndex: number) => {
    clonedLightboxes[currentIndex].isLightboxAccepted = true;
    setClonedLightboxes(clonedLightboxes);
    const totalLightboxLength = clonedLightboxes.length;
    if (totalLightboxLength === 2) {
      if (currentIndex === 0) {
        setPrevTitle(formatLightboxText(clonedLightboxes[1].name));
        setNextTitle(formatLightboxText(clonedLightboxes[1].name));
      } else {
        setPrevTitle(formatLightboxText(clonedLightboxes[0].name));
        setNextTitle(formatLightboxText(clonedLightboxes[0].name));
      }
    } else if (totalLightboxLength > 2) {
      if (currentIndex === 0) {
        setPrevTitle(formatLightboxText(clonedLightboxes[totalLightboxLength - 1].name));
        setNextTitle(formatLightboxText(clonedLightboxes[1].name));
      } else if (currentIndex === 1) {
        setPrevTitle(formatLightboxText(clonedLightboxes[0].name));
        setNextTitle(formatLightboxText(clonedLightboxes[2].name));
      } else if (currentIndex === totalLightboxLength - 1) {
        setPrevTitle(formatLightboxText(clonedLightboxes[currentIndex - 1].name));
        setNextTitle(formatLightboxText(clonedLightboxes[0].name));
      } else {
        setPrevTitle(formatLightboxText(clonedLightboxes[currentIndex - 1].name));
        setNextTitle(formatLightboxText(clonedLightboxes[currentIndex + 1].name));
      }
    } else {
      setPrevTitle('');
      setNextTitle('');
    }
  };

  // dateInput will be having date time that is converted to EST without zone information
  const getDateTimeInFormat = (dateInput: string) => {
    if (dateInput == null || dateInput === '') {
      return '';
    }
    var format = `DDDD 'at' t '${timeZoneAbbrevation}'`;
    return DateTime.fromISO(dateInput).toFormat(format);
  };

  const markAsReadOnChange = (event: React.ChangeEvent<HTMLInputElement>, data: Lightbox) => {
    setIsChecked(event.target.checked);
    clonedLightboxes.forEach((l) => {
      if (l.id === data.id) {
        l.isLightboxAccepted = event.target.checked;
      }
    });
    setClonedLightboxes(clonedLightboxes);
  };

  const renderLightboxHeader = (data: Lightbox) => {
    return (
      <Box sx={{ p: 1, backgroundColor: 'info.dark', height: '12%' }}>
        <Grid container>
          <Grid item xs>
            <Box>
              <span>{data.categoryString}</span>
              <Typography variant="h6">{data.name}</Typography>
            </Box>
          </Grid>
          <Grid item xs="auto" alignSelf="center">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.isLightboxAccepted ? data.isLightboxAccepted : isChecked}
                    onChange={(e) => markAsReadOnChange(e, data)}
                    disabled={data.isRead}
                    name="checked"
                    color="primary"
                  />
                }
                label="Mark as read"
              />
            </FormGroup>
          </Grid>
          <Grid item xs="auto">
            <CloseIcon onClick={handleClose} sx={{ lineHeight: 1, cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderLightboxContent = (data: Lightbox, replace: string) => {
    return (
      <ScrollableBox height="88%" minHeight={'10%'}>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            background: theme.palette.background.paper,
            px: 5,
            pt: 2,
          }}>
          {data.url != null ? (
            <LbButton href={data.url} target="_blank">
              View DailyPlay
            </LbButton>
          ) : (
            ''
          )}
          {data.eventStartDateTime !== '' ? (
            <Typography sx={{ pb: 1 }}>
              <strong>When : </strong>
              {data.eventStartDateTime}
            </Typography>
          ) : (
            ''
          )}
          {data.webinarLink != null ? (
            <LbButton href={data.webinarLink} target="_blank">
              {data.webinarLabel}
            </LbButton>
          ) : (
            ''
          )}
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: replace }} />
        </Box>
      </ScrollableBox>
    );
  };

  return (
    <Dialog
      open={closeLightBox}
      onClose={handleClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          borderRadius: 5,
          width: '60%',
          height: 600,
        },
      }}>
      <DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
        <Carousel
          fullHeightHover={false}
          autoPlay={false}
          animation="slide"
          onChange={(now: any) => {
            carouselChange(now);
          }}
          navButtonsAlwaysVisible
          cycleNavigation={clonedLightboxes.length > 1 ? true : false}
          navButtonsProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          // indicatorContainerProps={{
          //   style: {
          //     padding: 0,
          //     margin: 0,
          //     position: 'absolute',
          //     bottom: 10,
          //   },
          // }}
          NextIcon={
            <Tooltip title={nextTitle} placement="top">
              <KeyboardArrowRightIcon style={{ fontSize: 18 }} />
            </Tooltip>
          }
          PrevIcon={
            <Tooltip title={prevTitle} placement="top">
              <KeyboardArrowLeftIcon style={{ fontSize: 18 }} />
            </Tooltip>
          }>
          {clonedLightboxes.map((data: Lightbox, i: number) => {
            const replace = data.content.replace('<<<path>>>', `${link}`).replace(regexToRemoveHeaders, '');
            return (
              <Box key={i} height={600}>
                {renderLightboxHeader(data)}
                {renderLightboxContent(data, replace)}
              </Box>
            );
          })}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};
