import { FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import ThemeType, { getThemeType } from '@op/shared/src/models/theme-type';
import { accountState, themeState, userSettingState } from '@op/shared/src/states';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import React from 'react';
import { cloneDeep, MarketRegion, Permissions } from '@op/shared/src/models';

export const RegionSettingWidget: React.FC = () => {
  const regions = [
    {
      region: MarketRegion.US,
      name: 'us',
    },
    {
      region: MarketRegion.TMX,
      name: 'ca',
    },
  ];
  const account = useRecoilValue(accountState);
  const [setting, setSetting] = useRecoilState(userSettingState);

  if (!setting || !account) {
    return <></>;
  }

  const onChange = (value: string): void => {
    const region = value as MarketRegion;
    const clone = cloneDeep(setting);
    clone.preferredRegion = region;
    setSetting(clone);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="gender"
        name="activeTheme"
        value={setting.preferredRegion}
        onChange={(_, value) => onChange(value)}
        sx={{ flex: 1, flexDirection: 'row', cursor: 'pointer' }}>
        {regions.map((t) => (
          <Tooltip
            title={!account.hasPermission(t.region as unknown as Permissions) && `${t.region} permission not available`}
            sx={{ cursor: 'pointer' }}
            placement="top">
            <FormControlLabel
              key={t.region}
              disabled={!account.hasPermission(t.region as unknown as Permissions)}
              labelPlacement="end"
              value={t.region}
              control={<Radio color="primary" />}
              label={t.region}
            />
          </Tooltip>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
