import { IPortfolioCombination, PortfolioPositionEntity, RecommendationAlerts } from '.';
import { LegType } from '../enums/enums';
import { Position } from '../how';
import { BasicPositionModel } from '../how/basic-position-model';
import formatting from '../how/formatting';
import { unique } from '../how/helpers';

export class PortfolioCombinationEntity implements IPortfolioCombination {
  symbol = '';
  portfolioId = -1;
  portfolioName = '';
  portfolioDisplayName = '';
  majorTechnicalEvent = '';
  earningsDate = new Date();
  positions: PortfolioPositionEntity[] = [];
  recommendationAlerts = new RecommendationAlerts();
  hash = '';
  isGlobal = false;
  strategyName = '';
  strategyBarColor = '';
  id = '';
  portfolioTypeStatus = '';
  tradeIdeaSentiment = null;
  public static fromText = (data: PortfolioCombinationEntity): PortfolioCombinationEntity => {
    const value = new PortfolioCombinationEntity();
    value.symbol = data.symbol;
    value.portfolioId = data.portfolioId;
    value.portfolioName = data.portfolioName;
    value.portfolioDisplayName = data.portfolioDisplayName;
    value.majorTechnicalEvent = data.majorTechnicalEvent;
    value.earningsDate = data.earningsDate;
    value.positions = data.positions;
    value.recommendationAlerts = data.recommendationAlerts;
    value.hash = data.hash;
    value.isGlobal = data.isGlobal;
    value.strategyName = data.strategyName;
    value.strategyBarColor = data.strategyBarColor;
    value.id = data.id;
    value.portfolioTypeStatus = data.portfolioTypeStatus;
    value.tradeIdeaSentiment = data.tradeIdeaSentiment;
    return value;
  };

  // TODO:URVISH: Remove Dummy object when we have real data.
  public static fromData = (data: any): PortfolioCombinationEntity => {
    const porfolioAccountEntity = new PortfolioCombinationEntity();
    porfolioAccountEntity.symbol = data.symbol;
    porfolioAccountEntity.portfolioId = data.accountId;
    porfolioAccountEntity.portfolioName = data.accountName;
    porfolioAccountEntity.portfolioDisplayName = data.portfolioDisplayName;
    porfolioAccountEntity.majorTechnicalEvent = data.majorTechnicalEvent;
    porfolioAccountEntity.earningsDate = data.earningsDate;
    porfolioAccountEntity.positions = data.positions;
    porfolioAccountEntity.recommendationAlerts = RecommendationAlerts.fromText({
      key: 'UserCreated',
      alert: 'Buy to Open 1 Apr 16, 2021 145 Call',
      alertTitle: 'User Created Recommendation',
      title: 'User Created Recommendation',
      plainEnglish:
        'OptionsPlay has found an optimal call to write against your uncovered {NAME_IN_ADJUSTMENT_PANEL} to generate {INVERTED_COST} of additional income and lower your net cost basis.',
      adjustmentText: 'User Created',
      adjustmentAction: 'Open',
      positionStringName: 'Long 124 Share',
      shareQuantity: 124,
      recomendationDetails: [
        {
          id: '4289338d-962f-4158-ab58-e1118ac49f8b',
          expiry: '4/16/2021',
          strike: 145.0,
          ask: 0.58,
          bid: 0.52,
          premium: 4.1,
          action: 'Buy',
          quantity: -1.0,
          type: 'Call',
        },
      ],
      originalRecomendationDetails: null,
      strategyName: 'Share',
    }); //data.recommendationAlerts;
    porfolioAccountEntity.hash = data.hash;
    porfolioAccountEntity.isGlobal = data.isGlobal;
    porfolioAccountEntity.strategyName = data.strategyName;
    porfolioAccountEntity.strategyBarColor = data.strategyBarColor;
    porfolioAccountEntity.id = data.id;
    porfolioAccountEntity.portfolioTypeStatus = data.portfolioTypeStatus;
    porfolioAccountEntity.tradeIdeaSentiment = data.tradeIdeaSentiment;
    return porfolioAccountEntity;
  };

  public static fromSelf = (entity: PortfolioCombinationEntity): PortfolioCombinationEntity => {
    const clonedPorfolioAccountEntity = new PortfolioCombinationEntity();
    clonedPorfolioAccountEntity.symbol = entity.symbol;
    clonedPorfolioAccountEntity.portfolioId = entity.portfolioId;
    clonedPorfolioAccountEntity.portfolioName = entity.portfolioName;
    clonedPorfolioAccountEntity.portfolioDisplayName = entity.portfolioDisplayName;
    clonedPorfolioAccountEntity.majorTechnicalEvent = entity.majorTechnicalEvent;
    clonedPorfolioAccountEntity.earningsDate = entity.earningsDate;
    clonedPorfolioAccountEntity.positions = entity.positions;
    clonedPorfolioAccountEntity.recommendationAlerts = entity.recommendationAlerts;
    clonedPorfolioAccountEntity.hash = entity.hash;
    clonedPorfolioAccountEntity.isGlobal = entity.isGlobal;
    clonedPorfolioAccountEntity.strategyName = entity.strategyName;
    clonedPorfolioAccountEntity.strategyBarColor = entity.strategyBarColor;
    clonedPorfolioAccountEntity.id = entity.id;
    clonedPorfolioAccountEntity.portfolioTypeStatus = entity.portfolioTypeStatus;
    clonedPorfolioAccountEntity.tradeIdeaSentiment = entity.tradeIdeaSentiment;
    return clonedPorfolioAccountEntity;
  };

  hasOnlyStx = () => {
    let positions = this.positions;
    return !this.hasOption() && positions && !!positions.length;
  };

  hasOption = () => {
    return this.positions.some((position) => {
      return position.legType !== LegType.SECURITY;
    });
  };

  getExpiries = (positions?: BasicPositionModel[]) => {
    positions = positions || Position.extractPositions(this.positions as unknown as BasicPositionModel[]);
    let expiries = positions
      .filter((p) => p.expiry)
      .map((p) => p.expiry as Date)
      .sort((a: Date, b: Date) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });

    if (!expiries[0]) {
      return new Array<Date>();
    }
    const expiriesSet = new Set<Date>(expiries);
    return Array.from(expiriesSet);
  };

  getCombinationExpiry = (positions?: BasicPositionModel[]): Date | undefined => {
    let expiries = this.getExpiries(positions);
    if (expiries.length === 0) {
      return undefined;
    }
    return expiries[0];
  };

  daysToExpiry = (positions: BasicPositionModel[]) => {
    let expiry = this.getCombinationExpiry(positions);
    if (this.positions.length < 1 || this.hasOnlyStx() || !expiry) {
      return 999999;
    }
    return formatting.daysFromNow(new Date(expiry));
  };

  symbolsToSubscribe = () => {
    let uniqueSymbols: string[] = [];
    uniqueSymbols.push(this.symbol.trim().toUpperCase());
    for (let position of this.positions) {
      uniqueSymbols.push(position.symbol);
      uniqueSymbols.push(position.quotationSymbol);
    }
    return unique(uniqueSymbols);
  };
}
