import { Grid } from '@mui/material';
import { CombinationBuilder, ILeg, Position } from '@op/shared/src/models';
import { ActionTypes, PriceCalculationMethod, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import Combination from '@op/shared/src/models/how/combination';
import formatting from '@op/shared/src/models/how/formatting';
import { customizationState } from '@op/shared/src/states';
import {
  allCombinationsState,
  farthestExpirationState,
  strategyModifyState,
  tradingStrategiesUpdater,
  tradingStrategyAtomFamilyState,
  tradingStrategySelectedStrategyState,
  viewState,
} from '@op/shared/src/states/how/how-states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SimulatorOperationsWidget } from '../how/simulator-operations-widget';
import { SimulatorWidget } from '../how/simulator-widget';
import { StrategySentimentWidget } from './strategy-sentiment-widget';
import { TradingStrategyWidget } from './trading-strategy-widget';
import { CompanyNameWidget } from '../single-trade/company-name-widget';

export interface ITradingStrategiesWidgetProps {}

export const TradingStrategiesWidget: React.FC<ITradingStrategiesWidgetProps> = () => {
  const selectedCombinationId = useRecoilValue(tradingStrategySelectedStrategyState);
  //TODO: OnEditCombinationCallback shoudl be part of tradingStrategyWidget.tsx.
  const [combination, setCombination] = useRecoilState(tradingStrategyAtomFamilyState(selectedCombinationId));
  const allCombinations = useRecoilValue(allCombinationsState);
  const setStrategyModify = useSetRecoilState(strategyModifyState);
  const setView = useSetRecoilState(viewState);
  const [farthestExpiration, setFarthestExpiration] = useRecoilState(farthestExpirationState);
  const [farthestExpiry, setFarthestExpiry] = useState(farthestExpiration);
  const setTradingStrategies = useSetRecoilState(tradingStrategiesUpdater);
  const customization = useRecoilValue(customizationState);

  useEffect(() => {
    setView('trade');
    //setSimulatorOperation(tradeSimulatorOperationType.PLSimulator);
  }, []);

  useEffect(() => {
    if (formatting.sameDate(farthestExpiration, farthestExpiry)) {
      return;
    }
    setFarthestExpiry(farthestExpiration);
    setFarthestExpiration(farthestExpiration);
  }, [farthestExpiration]);

  const renderCombination = (index: number) => {
    return (
      <Grid key={index} item xs={4}>
        <TradingStrategyWidget data={index} />
      </Grid>
    );
  };

  const renderCombinations = () => {
    if (allCombinations.length === 0) {
      return null;
    }
    return (
      <Grid container columnSpacing={1}>
        {allCombinations.map((c, i) => renderCombination(i))}
      </Grid>
    );
  };

  const renderSimulatorOperations = () => {
    const operations = [
      { name: tradeSimulatorOperationType.PLSimulator, title: 'how.positionsManagement.js.plSimulator' },
      {
        name: tradeSimulatorOperationType.RiskInvestmentCalculator,
        title: 'how.toolbox.riskAndInvestmentCalculatorHeader.titles.riskAndInvestmentCalculator',
      },
      {
        name: tradeSimulatorOperationType.TradingRangeSimulator,
        title: 'how.positionsManagement.js.tradingRangeSimulator',
      },
      { name: tradeSimulatorOperationType.PlainEnglish, title: 'how.tradingStrategies.js.plainEnglish' },
    ];
    //Removed RiskAndInvestementCalculator if should not show.
    if (customization && !customization.showRiskAndInvestmentCalculator) {
      operations.splice(1, 1);
    }
    return <SimulatorOperationsWidget data={operations} />;
  };

  const onEditCombinationCallback = (action: string, legs?: ILeg[]) => {
    if (!combination) {
      throw new Error('No selected strategy');
    }
    if (action === ActionTypes.Change_Price_Bid_Ask) {
      updateAllCombinationsPriceMethod(PriceCalculationMethod.BID_ASK);
      return;
    }
    if (action === ActionTypes.Change_Price_Mid) {
      updateAllCombinationsPriceMethod(PriceCalculationMethod.MID);
      return;
    }
    if (legs) {
      let positions: Position[] = [];
      // TODO: Need to move w.r.t Combination-Builder.ts
      const newCombination = Combination.fromSelf(combination);
      for (let i = 0; i < legs.length; i++) {
        let newPosition = newCombination.replacePositionByLeg(legs[i], i);
        positions.push(newPosition);
      }
      newCombination.positions = positions;
      updateTradingStrategies(newCombination);
    }
  };

  const updateAllCombinationsPriceMethod = (type: PriceCalculationMethod) => {
    let clonedCombinations: Combination[] = [];
    allCombinations.forEach((combination) => {
      if (combination) {
        const newCombination = new CombinationBuilder(combination).updatePriceMethod(type).build();
        clonedCombinations.push(newCombination);
      }
    });
    if (selectedCombinationId) {
      setStrategyModify(clonedCombinations[parseInt(selectedCombinationId)]);
    }
    setTradingStrategies(clonedCombinations);
  };

  const updateTradingStrategies = (combination: Combination) => {
    combination.profileSentiments();
    setStrategyModify(combination);
    setCombination(combination);
  };

  return (
    <Grid container justifyContent="center" pl={1} pr={1}>
      <Grid item xs={12}>
        {!customization.showQuoteBar && <CompanyNameWidget canRenderClose={false} />}
      </Grid>
      <Grid item xs={12}>
        <StrategySentimentWidget />
      </Grid>
      <Grid item xs={12}>
        {renderCombinations()}
      </Grid>
      <Grid item xs={12}>
        {renderSimulatorOperations()}
      </Grid>
      <Grid item xs={12}>
        <SimulatorWidget
          combinations={allCombinations}
          onEditCombinationCallback={onEditCombinationCallback}
          shouldIncludeStrategyConstructor
        />
      </Grid>
    </Grid>
  );
};
