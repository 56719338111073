import { Grid, Theme, useTheme } from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { IUserSubscriptionFeatures } from '@op/shared/src/models';
import { Permissions, SubscriptionStatus, UserSubscriptionPeriod } from '@op/shared/src/models/enums/enums';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { getSubscriptionPricingModels, getUserSubscriptions } from '@op/shared/src/services';
import {
  accountState,
  configurationState,
  customizationState,
  isConfigurationUpdatedState,
  stepperUpdaterState,
  subscriptionCurrentScreenState,
  subscriptionCurrentState,
  subscriptionPlanPricesModelState,
  userSubscriptionFeaturesState,
} from '@op/shared/src/states';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../styled';
import {
  getDiscountLabel,
  getModifiedUserCachedSubscription,
  prepareSubscriptionPlan,
} from '../subscription/subscription-helper';

export const SubscriptionStatusWidget: React.FC = () => {
  const theme = useTheme<Theme>();
  const { t } = React.useContext(LocalizationContext);
  const account = useRecoilValue(accountState);
  const configuration = useRecoilValue(configurationState);
  const isConfigurationUpdated = useRecoilValue(isConfigurationUpdatedState);
  const customization = useRecoilValue(customizationState);
  const currentSubscription = useRecoilValue(subscriptionCurrentState);
  const [userSubscriptionFeatures, setUserSubscriptionFeatures] = useRecoilState(userSubscriptionFeaturesState);
  const [subscriptionPlanPrices, setSubscriptionPlanPricesModel] = useRecoilState(subscriptionPlanPricesModelState);
  const setSubscriptionCurrentScreen = useSetRecoilState(subscriptionCurrentScreenState);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const navigate = useNavigate();

  const { deleteRequested } = account?.securityModel || {};

  useEffect(() => {
    if (!account || !customization || customization.isEmbeddingPlatform) {
      return;
    }
    // if (isConfigurationUpdated > 2 || isConfigurationUpdated < 1) {
    //   return;
    // }
    if (userSubscriptionFeatures) {
      return;
    }
    (async () => {
      await fetchUserSubscriptions();
    })();
  }, [account, isConfigurationUpdated, userSubscriptionFeatures]);

  useEffect(() => {
    if (!userSubscriptionFeatures) {
      return;
    }
    if (subscriptionPlanPrices) {
      return;
    }
    (async () => {
      await fetchSubscriptionPricingModels(userSubscriptionFeatures);
    })();
  }, [userSubscriptionFeatures]);

  const fetchUserSubscriptions = async () => {
    const response = await getUserSubscriptions();
    if (response.hasErrors) {
      return;
    }
    const modifiedUserSubscription = getModifiedUserCachedSubscription(response.data);
    setUserSubscriptionFeatures(modifiedUserSubscription);
  };

  const fetchSubscriptionPricingModels = async (features: IUserSubscriptionFeatures) => {
    const response = await getSubscriptionPricingModels();
    if (response.hasErrors) {
      return;
    }
    const modifiedPlan = prepareSubscriptionPlan(response.data, features);
    setSubscriptionPlanPricesModel(modifiedPlan);
  };

  if (!account || !currentSubscription || !configuration) {
    return null;
  }

  const profileSubscriptionPermission = account.securityModel.hasPermission(Permissions.MANAGE_PROFILE_SUBSCRIPTIONS);
  const individualSubscriptionEnabled = configuration.individualSubscriptionEnabled;

  if (!profileSubscriptionPermission || !individualSubscriptionEnabled) {
    return;
  }

  const endDate = DateTimeHelper.resolveDate(currentSubscription.endDate);
  const daysRemaining = NumberFormatHelper.floor(DateTimeHelper.daysFromNow(endDate));
  const subscriptionStatus = currentSubscription.subscriptionStatus;

  const StatusText = {
    [SubscriptionStatus.ACTIVEFREE]: 'Free Basic User',
    [SubscriptionStatus.CANCELED]: t('subscriptions.subscriptionCancelled'),
    [SubscriptionStatus.CANCELED_NOT_EXPIRED]: t('subscriptions.subscriptionCancelled'),
    [SubscriptionStatus.EXPIRED]: t('subscriptions.subscriptionExpired'),
    [SubscriptionStatus.SUSPENDED]: t('subscriptions.subscriptionSuspended'),
    [SubscriptionStatus.SUSPENDED_NOT_EXPIRED]: t('subscriptions.subscriptionSuspended'),
  };

  const getDiscountString = () => {
    if (!currentSubscription) {
      return '';
    }

    const { featureKey, planType } = currentSubscription;

    return getDiscountLabel(subscriptionPlanPrices, featureKey, planType);
  };

  const renderTrialExpiryTextView = () => {
    let message = t('subscriptions.freeTrial');

    if (daysRemaining < 0) {
      message = t('subscriptions.freeTrialEnded');
    }

    if (daysRemaining === 0) {
      message = t('subscriptions.freeTrialEndsToday');
    }

    if (daysRemaining === 1) {
      message = `${t('subscriptions.freeTrialEndsin')} ${daysRemaining} ${t('subscriptions.day')}`;
    }

    if (daysRemaining <= 7 && daysRemaining > 1) {
      message = `${t('subscriptions.freeTrialEndsin')} ${daysRemaining} ${t('subscriptions.days')}`;
    }

    return renderStatusTextView(message, message === t('subscriptions.freeTrial') && theme.palette.success.main);
  };

  const renderStatusTextView = (message: ReactNode, color?: string) => {
    return (
      <OPBoldTypograpghy
        variant="body1"
        textAlign="center"
        sx={{ color: color ? theme.palette.success.main : theme.palette.error.main }}>
        {message}
      </OPBoldTypograpghy>
    );
  };

  const canShowHeaderStatus = () => {
    /**
     * !Note : As per the discussion Subscribe now button should be
     * !hidden when there is active trail and upcoming bundle
     */
    if (!currentSubscription || !userSubscriptionFeatures) {
      return false;
    }

    const upcomingSubscription = userSubscriptionFeatures.features.filter(
      (f) => f.title === UserSubscriptionPeriod.UPCOMING,
    );

    const hasPendingSubscription = upcomingSubscription.some((u) =>
      u?.subscriptionList?.some((s) => s.featureType === 'Bundle'),
    );

    if (
      hasPendingSubscription &&
      (currentSubscription.subscriptionStatus === SubscriptionStatus.ACTIVE_TRIAL ||
        currentSubscription.subscriptionStatus === SubscriptionStatus.CANCELED_NOT_EXPIRED)
    ) {
      return false;
    }
    return true;
  };

  const renderActionButtonView = () => {
    return (
      <Grid item xs="auto">
        <Grid
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(deleteRequested ? '/profile/privacy' : '/profile/subscriptions');
          }}>
          <OPBoldTypograpghy
            textAlign="center"
            px={1}
            sx={{
              color: '#fff',
              backgroundColor: theme.palette.success.main,
              borderRadius: 1,
            }}
            onClick={() => {
              if (deleteRequested) {
                return;
              }
              setSubscriptionCurrentScreen('ChangeSubscription');
              setActiveStep('changePlan');
            }}>
            {deleteRequested
              ? t('profile.changePassword.buttons.cancelDeletion')
              : `${t('subscriptions.subscribeNow')}${getDiscountString()}`}
          </OPBoldTypograpghy>
        </Grid>
      </Grid>
    );
  };

  const renderDeletionView = () => {
    return (
      <>
        {renderStatusTextView(t('profile.changePassword.labels.pendingDeleteAccount'))}
        {renderActionButtonView()}
      </>
    );
  };

  const renderSubsciptionViews = () => {
    if (!canShowHeaderStatus()) {
      return;
    }

    switch (subscriptionStatus) {
      case SubscriptionStatus.ACTIVE_TRIAL:
      case SubscriptionStatus.EXPIRED_TRIAL:
        return (
          <>
            {renderTrialExpiryTextView()}
            {renderActionButtonView()}
          </>
        );
      case SubscriptionStatus.ACTIVEFREE:
      case SubscriptionStatus.CANCELED:
      case SubscriptionStatus.CANCELED_NOT_EXPIRED:
      case SubscriptionStatus.EXPIRED:
      case SubscriptionStatus.SUSPENDED:
      case SubscriptionStatus.SUSPENDED_NOT_EXPIRED:
        return (
          <>
            {renderStatusTextView(StatusText[subscriptionStatus])}
            {renderActionButtonView()}
          </>
        );
      default:
        return null;
    }
  };

  const renderViews = () => {
    if (deleteRequested) {
      return renderDeletionView();
    }

    return renderSubsciptionViews();
  };

  return <Grid>{renderViews()}</Grid>;
};
