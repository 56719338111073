import { customizationState, howDataState, isDataLoadingState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { HowSkeletonWidget } from './how-skeleton-widget';
import { TradingStrategiesWidget } from '../trade';
import { CompanyNameWidgetLoader } from '../income';

export const HowWidget: React.FC = () => {
  const howData = useRecoilValue(howDataState);
  const isDataLoading = useRecoilValue(isDataLoadingState);
  const customization = useRecoilValue(customizationState);

  if (isDataLoading || !howData) {
    return (
      <>
        {!customization.showQuoteBar ? <CompanyNameWidgetLoader /> : null}
        <HowSkeletonWidget />
      </>
    );
  }

  return <TradingStrategiesWidget />;
};
