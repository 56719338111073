import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from '@mui/material';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import { customizationState, howDataState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { ExpandedQuoteHubWidget, SentimentLongTermWidget, SentimentShortTermWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';

export interface ICompanyNameWidgetProps {
  isShared?: boolean;
  sharedBy?: string;
  creationDate?: Date;
  onClose?: () => void;
  canRenderClose: boolean;
}

export const CompanyNameWidget: React.FC<ICompanyNameWidgetProps> = ({
  sharedBy,
  creationDate: createdDate,
  onClose,
  canRenderClose,
}: ICompanyNameWidgetProps) => {
  const { t } = React.useContext(LocalizationContext);
  const howData = useRecoilValue(howDataState);
  const customization = useRecoilValue(customizationState);

  if (!howData) {
    return;
  }

  const quote = howData.quote;
  const { symbol, name } = quote;
  const symbolDisplay = formatting.symbolDotExchangeToSymbol(symbol);

  if (!customization.showQuoteBar) {
    return (
      <Grid container justifyContent="space-between" alignItems="center" py={0.4}>
        <Grid item xs container>
          <Typography variant="subtitle1">
            {symbolDisplay} {name}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          {canRenderClose ? <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} /> : null}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs container>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {symbolDisplay} {name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ExpandedQuoteHubWidget data={symbol} variant="subtitle1" />
        </Grid>
        <Grid item xs={12}>
          {sharedBy && sharedBy.trim() !== '' && (
            <Typography variant="body1" component="span">
              <b>{sharedBy}</b> {t('how.singleTrade.labels.sharedWithYou')}
            </Typography>
          )}
          {sharedBy && createdDate && (
            <Typography variant="body1" component="span">
              {` As of ${DateTimeHelper.format(createdDate)}`}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <Grid container rowSpacing={0.5} alignItems="flex-end" display={'grid'}>
          <Grid item xs={12}>
            <SentimentShortTermWidget symbol={symbol} />
          </Grid>
          <Grid item xs={12}>
            <SentimentLongTermWidget symbol={symbol} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs="auto">
        {canRenderClose ? <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} /> : null}
      </Grid>
    </Grid>
  );
};
