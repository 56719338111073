import { PortfolioPosition } from '.';
import { SentimentModel } from '../../hubs/sentiment-model';
import { LegType } from '../enums/enums';
import { ExpandedQuote } from '../how';
import BasicCombination from '../how/basic-combination';
import formatting from '../how/formatting';
import { unique } from '../how/helpers';
import { PortfolioCombinationEntity } from './portfolio-combination-entity';

export class PortfolioCombination extends BasicCombination {
  positions: PortfolioPosition[] = [];
  isGlobal = false;
  portfolioId: number = 0;
  portfolioName = '';
  portfolioDisplayName = '';
  advisor: string = '';
  advisorId: string | number = '';
  moneyType: string = '';
  accountIdentifier: string = '';
  accountAdvisorName: string = '';
  faNumber: string = '';
  strategyQuantity: number = 0;
  positionType: string = '';
  hash: string = '';
  earningsDate: any;
  portfolioTypeStatus = '';
  tradeIdeaSentiment: string | null = null;
  private constructor() {
    super();
  }

  //TODO : syrahsentiments is not used needs to be removed.
  static fromData = (
    data: PortfolioCombinationEntity,
    positionModels: PortfolioPosition[],
    underlyingQuote: ExpandedQuote | undefined,
    syrahSentiments: SentimentModel | undefined,
  ) => {
    const combination = new PortfolioCombination();
    combination.fromPortolioCombination(positionModels, underlyingQuote);
    //TODO: Should clone it.
    combination.positions = positionModels.map((p) => p);
    combination.isGlobal = data.isGlobal;
    combination.portfolioId = data.portfolioId;
    combination.portfolioName = data.portfolioName;
    combination.portfolioDisplayName = `${data.portfolioName} ${data.portfolioDisplayName}`;
    combination.symbol = data.symbol;
    combination.hash = data.hash;
    combination.portfolioTypeStatus = data.portfolioTypeStatus;
    combination.earningsDate = formatting.throwTimezoneOffset(data.earningsDate);
    combination.tradeIdeaSentiment = data.tradeIdeaSentiment;
    combination.initialize(data);
    return combination;
  };

  static fromSelf(self: PortfolioCombination) {
    const clone = new PortfolioCombination();
    //clone.fromSelf(self);
    clone.originalLegs = self.originalLegs;
    clone.quote = ExpandedQuote.fromSelf(self.quote);
    clone.symbol = self.symbol;
    //TODO: Important Note, chain, predictions and stdDev should not be part of basicCombination, because it does not name sense in PorfolioCombination.
    //clone.chain = self.chain; //TODO: Create fromSelf in OptionChain.
    //clone.predictions = self.predictions;
    //clone.stdDev = self.stdDev;
    clone.priceCalculationMethod = self.priceCalculationMethod;
    clone.optionType = self.optionType;
    clone.originalMultiplier = self.originalMultiplier;
    clone.defaultDaysToFindExpiry = self.defaultDaysToFindExpiry;
    clone.isIncomeSpecific = self.isIncomeSpecific;
    clone.allowNegativeCostBasis = self.allowNegativeCostBasis;
    clone.priceCalculationMethod = self.priceCalculationMethod;
    clone.isRoll = self.isRoll;
    clone.combinationType = self.combinationType;
    clone.positions = self.positions.map((p) => PortfolioPosition.fromSelf(p));
    clone.isGlobal = self.isGlobal;
    clone.portfolioId = self.portfolioId;
    clone.portfolioName = self.portfolioName;
    clone.portfolioDisplayName = self.portfolioDisplayName;
    clone.symbol = self.symbol;
    clone.hash = self.hash;
    clone.earningsDate = self.earningsDate;
    clone.portfolioTypeStatus = self.portfolioTypeStatus;
    clone.tradeIdeaSentiment = self.tradeIdeaSentiment;
    return clone;
  }

  private initialize = (portfolioCombinationData: PortfolioCombinationEntity) => {
    if (portfolioCombinationData.positions.length === 0) {
      return;
    }
    const position = portfolioCombinationData.positions[0];
    this.advisor = position.accountAdvisor && position.accountAdvisor[0] ? position.accountAdvisor[0].faNumber : '';
    this.advisorId = position.accountAdvisor && position.accountAdvisor[0] ? position.accountAdvisor[0].id : '';
    this.moneyType = position.moneyType ? position.moneyType : '';
    this.strategyQuantity = position.quantity;
    this.positionType = position.positionType;
    this.accountIdentifier = position.portfolioPositionAccount?.accountIdentifier || '';
    const accountAdvisor = position.accountAdvisor && position.accountAdvisor[0];
    this.accountAdvisorName = accountAdvisor?.positionAccountAdvisorName || '';
    this.faNumber = accountAdvisor?.faNumber || '';
  };

  // This method is duplicated from BasicCombinationModel.ts because of non-generic position type. I guess this is only required for portfolioPosition.
  private compositionMarketValueCalulatorDuplicate(
    marketValueGetter: (position: PortfolioPosition) => number,
    positionsToIterate: PortfolioPosition[],
  ) {
    let result = positionsToIterate.reduce((acc: number, pos: PortfolioPosition, index: number) => {
      let value = marketValueGetter(pos) || 0;
      let qty = pos.legType === LegType.SECURITY ? pos.quantity / 100 : pos.quantity;
      return acc + value * qty;
    }, 0);
    return result;
  }

  //Movd from BasicCombination.ts
  get mark() {
    let costBasis = 0;
    const absQuantity = this.hasOnlyStx() ? this.absQuantity() / 100 : this.absQuantity();
    const positionWithNoCostBasis = this.positions.find((p) => !p.price());
    if (positionWithNoCostBasis) {
      return 0;
    }
    this.positions.forEach((p) => {
      costBasis += p.legType === LegType.SECURITY ? p.price() * (p.quantity / 100) : p.price() * p.quantity;
    });
    const result = costBasis / absQuantity;
    return Math.abs(result);
  }

  get strategyIconCss() {
    const matchedTemplate = this.matchedTemplate();
    let iconCssName = (matchedTemplate?.iconName || '').split(' ').join('');
    if (!iconCssName || iconCssName === '') {
      iconCssName = 'CustomStrategy';
    }
    return `stg-${iconCssName}`;
  }

  // Overwritten logic of basic-combination.costBasis()
  costBasis = () => {
    let costBasis = 0;
    const absQuantity = this.hasOnlyStx() ? this.absQuantity() / 100 : this.absQuantity();
    const positionWithNoCostBasis = this.ownedPositions().find((p) => p.costBasis === undefined);
    if (positionWithNoCostBasis) {
      return 0;
    }
    this.ownedPositions().forEach((p) => {
      costBasis +=
        p.legType === LegType.SECURITY
          ? (p.costBasis as number) * (p.quantity / 100)
          : (p.costBasis as number) * p.quantity;
    });
    const result = costBasis / absQuantity;
    return this.allowNegativeCostBasis ? result : Math.abs(result);
  };

  getLegsSummary = () => {
    let noOfCall = 0;
    let noOfPut = 0;
    let minPutStrike = null;
    let maxCallStrike = null;
    const positions = this.positions;
    for (var i = 0; i < positions.length; i++) {
      var pos = positions[i];
      if (pos.isCallType()) {
        noOfCall += pos.quantity;
        if (!pos.strike) {
          throw new Error('position strike is undefined');
        }
        if (maxCallStrike == null || pos.strike > maxCallStrike) {
          maxCallStrike = pos.strike;
        }
      } else if (pos.isPutType()) {
        noOfPut += pos.quantity;
        if (!pos.strike) {
          throw new Error('position strike is undefined');
        }
        if (minPutStrike == null || pos.strike < minPutStrike) {
          minPutStrike = pos.strike;
        }
      } else {
        noOfCall += pos.quantity / this.originalMultiplier;
        noOfPut -= pos.quantity / this.originalMultiplier;
      }
    }
    return {
      noOfCall: noOfCall,
      noOfPut: noOfPut,
      maxCallStrike: maxCallStrike,
      minPutStrike: minPutStrike,
    };
  };

  getLegsToClosePosition = () => {
    return this.positions.map((position) => {
      return position.getInvertedRawLeg();
    });
  };

  symbolsToSubscribe = () => {
    let uniqueSymbols: string[] = [];
    uniqueSymbols.push(this.symbol.trim().toUpperCase());
    for (let position of this.positions) {
      uniqueSymbols.push(position.symbol);
      uniqueSymbols.push(position.ulSymbol);
    }
    return unique(uniqueSymbols);
  };
}
