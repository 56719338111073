import { Grid, Typography, useTheme } from '@mui/material';
import { Combination } from '@op/shared/src/models';
import { Permissions } from '@op/shared/src/models/enums/enums';
import SingleTrade from '@op/shared/src/models/how/single-trade';
import { accountState, tradingStrategySelectedStrategyState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { SingleShareWidget } from '../common';
import { TradeOptionsPlayScoreWidget } from '../common/options-play-score-widget';
import { CompanyNameWidget } from './company-name-widget';

export interface ISingleTradeCompanyInformationWidgetProps {
  data: SingleTrade;
  combination: Combination;
  isShared?: boolean;
  sharedBy?: string;
  creationDate?: Date;
  onClose: () => void;
}

export const SingleTradeCompanyInformationWidget: React.FC<ISingleTradeCompanyInformationWidgetProps> = ({
  data,
  combination,
  isShared,
  sharedBy,
  creationDate: createdDate,
  onClose,
}: ISingleTradeCompanyInformationWidgetProps) => {
  const selectedCombinationId = useRecoilValue(tradingStrategySelectedStrategyState);
  const account = useRecoilValue(accountState);
  const theme = useTheme();

  const index = parseInt(selectedCombinationId || '0');
  const isIncome = data.combination.isIncome;

  const isSingleShare = () => {
    if (!account || !account.securityModel) {
      return false;
    }

    const permissions = [
      Permissions.ALLOW_SHARE_BY_EMAIL,
      Permissions.ALLOW_SHARE_BY_EMAIL_CLIENT,
      Permissions.ALLOW_SHARE_BY_TWITTER,
      Permissions.ALLOW_SHARE_TO_STOCK_TWITS,
      Permissions.ALLOW_COPY_LINK,
    ];

    return permissions.some((permission) => account.securityModel.hasPermission(permission));
  };

  const gridSize = () => {
    const size = isSingleShare() ? (isIncome ? 11 : 7) : isIncome ? 12 : 8;
    return size;
  };

  return (
    <Grid container>
      <CompanyNameWidget onClose={onClose} creationDate={createdDate} sharedBy={sharedBy} canRenderClose={true} />
      <Grid
        container
        justifyContent="space-between"
        alignItems={'center'}
        style={{
          backgroundColor: theme.palette.info.dark,
          borderRadius: theme.shape.borderRadius,
          paddingLeft: 1,
          paddingRight: 1,
        }}>
        <Grid item xs={gridSize()}>
          <Typography variant="subtitle2" fontWeight="bold" p={1} lineHeight={1.2}>
            {data.combinationTitle}
          </Typography>
        </Grid>
        {!isIncome && (
          <Grid item xs={4}>
            <TradeOptionsPlayScoreWidget index={index} />
          </Grid>
        )}
        {isSingleShare() && (
          <Grid item xs={1} container justifyContent="center" alignItems="center">
            <SingleShareWidget comb={combination} data={data} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
