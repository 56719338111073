import { Button, Grid, SelectChangeEvent, useTheme } from '@mui/material';
import { IncomeCombination, Leg, TradeTicket } from '@op/shared/src/models';
import { getRegionAndFormattedSymbol } from '@op/shared/src/models/common-helper';
import { BuyOrSell, LegType } from '@op/shared/src/models/enums/enums';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import {
  accountState,
  cachedSecuritiesDataState,
  howDataState,
  selectedWatchListIdState,
  viewState,
  whatIfSimulatorState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import { MenuProps, OPBoldTypograpghy, OPSelectField, StyledDropdownItemWithBorder } from '../styled';
import { formatIndexSymbol, formatSlashDotSymbol, ITradeProps } from './models/ts-helpers';
import {
  tsAuthenticationStatusState,
  tsDockState,
  tsEnvironmentState,
  tsOrderTicketState,
  tsSelectedTabState,
} from './states/trade-station-states';
import {
  confirmOrderTicketState,
  tsBidMidAskValueState,
  tsLimitPriceState,
  tsOrderPlacementState,
  tsOrdetTicketPositionState,
  tsTradeOrderPropsState,
} from './states/ts-ticket-states';
import { TSEnvSelectionWidget } from './ts-env-selection-widget';

interface Props {
  tradeTicket: TradeTicket | undefined;
}

export const TSAuthenticateWidget: React.FC<Props> = ({ tradeTicket }: Props) => {
  const theme = useTheme();
  const account = useRecoilValue(accountState);
  const [open, setOpen] = useState(false);
  const isAuthenticated = useRecoilValue(tsAuthenticationStatusState);
  const setEnv = useSetRecoilState(tsEnvironmentState);
  const setTSSelectedTab = useSetRecoilState(tsSelectedTabState);
  const [brokerAccount, setBrokerAccount] = useState('');
  const [showDock, setShowDock] = useRecoilState(tsDockState);
  const setTrade = useSetRecoilState(tsOrderPlacementState);
  const resetConfirmOrderTicket = useResetRecoilState(confirmOrderTicketState);
  const [isTSButtonClicked, setIsTSButtonClicked] = useState(false);
  const setTsTradeOrderProps = useSetRecoilState(tsTradeOrderPropsState);
  const resetTsOrderTicketPositions = useResetRecoilState(tsOrdetTicketPositionState);
  const resetBidMidAsk = useResetRecoilState(tsBidMidAskValueState);
  const setOrderTicketValue = useSetRecoilState(tsOrderTicketState);
  const setNotifications = useSetRecoilState(notificationsState);
  const howData = useRecoilValue(howDataState);
  const cachedSecurityData = useRecoilValue(cachedSecuritiesDataState);
  const setTsLimitPrice = useSetRecoilState(tsLimitPriceState);

  const view = useRecoilValue(viewState);
  const selectedWatchListId = useRecoilValue(selectedWatchListIdState);
  const watchListRoute = useMatch('/watchlist');
  const whatIfSimulator = useRecoilValue(whatIfSimulatorState);

  /*
    If tradeTicket is changed and TS button is clicked
    Reset the order-ticket widget
  */
  useEffect(() => {
    if (!tradeTicket || !isTSButtonClicked) {
      return;
    }
    resetConfirmOrderTicket();
  }, [tradeTicket, isTSButtonClicked]);

  useEffect(() => {
    if (!open) {
      setBrokerAccount('');
    }
  }, [open]);
  if (!howData) {
    return;
  }
  if (!account || !account.isAllowTradeStationAccountLinking) {
    return null;
  }

  if (tradeTicket && tradeTicket.combination && tradeTicket.combination.isPortfolio) {
    return null;
  }

  const handleChange = (event: SelectChangeEvent) => {
    setBrokerAccount(event.target.value as string);
    if (event.target.value !== '') {
      setEnv('none');
      setOpen(!open);
    }
  };

  const createOrderTicket = () => {
    if (!tradeTicket) {
      return;
    }
    // const [newSymbol, exchangeCode] = splitSymbol(tradeTicket.combination.symbol);
    // const region = getRegionBySymbol(newSymbol, exchangeCode, cachedSecurityData.data);
    const { region, formattedSymbolByRegion } = getRegionAndFormattedSymbol(tradeTicket.combination.symbol);

    if (!region) {
      setNotifications([{ type: 'error', content: `Region Unavailable` }]);
      return false;
    }

    const symbol = formattedSymbolByRegion;
    // if (region !== 'US') {
    //   setNotifications([{ type: 'error', content: `${region} is not allowed to trade into TradeStation` }]);
    //   return false;
    // }
    const avgPremium = tradeTicket.avgPremium();
    const positions: ITradeProps[] = tradeTicket.allLegs().map((pos, index) => {
      const isOptionSymbol = pos.type !== LegType.SECURITY;
      const isIndex = formatting.checkIndices(howData.quote.exchange);
      const strike = pos.type === LegType.SECURITY ? undefined : Number(pos.strike);
      const position: ITradeProps = {
        underlyingSymbol: isIndex ? formatIndexSymbol(symbol) : formatSlashDotSymbol(symbol, false, true),
        qty: pos.quantity.toString(),
        symbol:
          pos.type === LegType.SECURITY
            ? formatSlashDotSymbol(symbol, isOptionSymbol, true)
            : `${formatSlashDotSymbol(symbol, isOptionSymbol, true)} ${DateTimeHelper.toExpiryKey(
                pos.expiry,
                'yyMMdd',
              )}${pos.type.charAt(0)}${strike}`,
        longShort: pos.getActionName().includes('BUY') ? 'Long' : 'Short',
        action: 'trade',
        initiator: 'opTradeTicket',
      };
      return position;
    });
    setTsLimitPrice(avgPremium.toFixed(2));
    setTsTradeOrderProps(positions);
    resetConfirmOrderTicket();

    /**
     * NOTE: Below line is used only to work in local system
     * It should be used to load the trade ticket back again, if dual logon issue appears
     */
    preserveTradeTicketLocal();
    return true;
  };

  const preserveTradeTicketLocal = () => {
    const allLegs = tradeTicket.allLegs();
    const legs = allLegs.map((l, i) => {
      const leg = Leg.fromBasicPosition(
        l.quantity,
        l.type,
        l.expiry,
        l.strike,
        undefined,
        l.quantity > 0 ? BuyOrSell.BUY : BuyOrSell.SELL,
      );
      leg.premium = l.price;
      return leg;
    });
    const whatIfDate = whatIfSimulator.whatIfDate || new Date();
    const targetDate = new Date(
      Date.UTC(whatIfDate.getFullYear(), whatIfDate.getMonth(), whatIfDate.getDate(), 0, 0, 0),
    );
    const tradeTicketComb = {
      ...(watchListRoute && { id: selectedWatchListId }),
      symbol: tradeTicket.combination.symbol,
      view: view,
      legs: legs,
      priceCalculationMethod: tradeTicket.combination.priceCalculationMethod,
      targetDate: targetDate.toISOString(),
      targetPrice: whatIfSimulator?.whatIfSPrice,
      ...(tradeTicket.combination.isIncome && {
        priority: (tradeTicket.combination as IncomeCombination).priority,
        costBasis: (tradeTicket.combination as IncomeCombination).costBasis(),
        shares: (tradeTicket.combination as IncomeCombination).absQuantity(),
      }),
    };
    localStorage.setItem('tradeTicketCombination', JSON.stringify(tradeTicketComb));
    // console.log(
    //   'tradeTicketCombination' +
    //     ' = ' +
    //     ((localStorage['tradeTicketCombination'].length * 16) / (8 * 1024)).toFixed(2) +
    //     ' KB',
    // );
    // console.log('income', (tradeTicket.combination as IncomeCombination).getRawPositions());
  };

  const renderTradeStationConnectView = () => {
    return (
      <>
        <Grid container alignItems={'center'}>
          <Grid item xs={1} textAlign={'center'}>
            <OPBoldTypograpghy sx={{ color: theme.palette.primary.main }}>OR</OPBoldTypograpghy>
          </Grid>
          <Grid
            item
            xs={11}
            sx={{
              backgroundColor: theme.palette.primary.light,
              p: 1,
              borderRadius: 2,
            }}>
            <Grid container columnSpacing={1} alignItems={'center'}>
              <Grid item xs={4} alignItems={'center'} textAlign={'center'}>
                <OPSelectField
                  variant="outlined"
                  displayEmpty
                  value={brokerAccount}
                  disableUnderline
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  sx={{
                    width: '100%',
                    height: 35,
                    '& .MuiSvgIcon-root': {
                      color: theme.palette.selectAndTextField.main,
                    },
                  }}>
                  <StyledDropdownItemWithBorder color="default" value="">
                    Connect to
                  </StyledDropdownItemWithBorder>
                  <StyledDropdownItemWithBorder color="default" value={'tradeStation'}>
                    <IconComponent name="tradeStation" fill={theme.palette.primary.main} />
                  </StyledDropdownItemWithBorder>
                </OPSelectField>
              </Grid>
              <Grid item xs={'auto'} marginX={1}>
                <OPBoldTypograpghy>with a Broker to trade this strategy</OPBoldTypograpghy>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {open && <TSEnvSelectionWidget open={open} setOpen={setOpen} tradeTicket={tradeTicket} />}
      </>
    );
  };

  const renderAuthenticatedView = () => {
    return (
      <Grid container alignItems={'center'}>
        <Grid item xs={1} textAlign={'center'}>
          <OPBoldTypograpghy sx={{ color: theme.palette.primary.main }}>OR</OPBoldTypograpghy>
        </Grid>
        <Grid
          item
          container
          xs={11}
          alignItems={'center'}
          sx={{
            backgroundColor: theme.palette.primary.light,
            p: 1,
            borderRadius: 1,
          }}>
          <Grid item xs={3}>
            <OPBoldTypograpghy>Trade this strategy at</OPBoldTypograpghy>
          </Grid>
          <Grid item xs={8}>
            <Button
              id={`ts_trade_now_button`}
              onClick={() => {
                resetTsOrderTicketPositions();
                resetBidMidAsk();
                if (!createOrderTicket()) {
                  return;
                }
                setIsTSButtonClicked(!isTSButtonClicked);
                setTrade(true);
                setTSSelectedTab('orderTicket');
                setShowDock(!showDock);
                setOrderTicketValue('default');
              }}
              variant="contained"
              sx={{ paddingX: 4 }}>
              <IconComponent name="tradeStation" fill="#fff" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return isAuthenticated ? renderAuthenticatedView() : renderTradeStationConnectView();
};
