import { Grid, Typography, useTheme } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import React from 'react';
import { useRecoilState } from 'recoil';
import { MenuProps, OPSelectDropDownHeader, OPSelectField, StyledDropdownItemWithBorder } from '../../styled';
import { orderTypes } from '../models';
import { commonOrderRequestState } from '../states/ts-ticket-states';
import { TSTextField } from '../ts-styles-component';
import { TSOrderType } from '@op/shared/src/models/enums/enums';
interface Props {
  orderType: string;
  setOrderType: (type: string) => void;
}

export const OrderTypeWidget: React.FC<Props> = ({ setOrderType, orderType }: Props) => {
  const theme = useTheme();
  const [commonOrderRequest, setCommonOrderRequest] = useRecoilState(commonOrderRequestState);

  const renderOrderType = () => {
    return (
      <Grid container>
        <Typography variant="captionSmall">Order Type</Typography>
        <Typography color={theme.palette.error.main} lineHeight={1.2}>
          *
        </Typography>
        <OPSelectField
          variant="outlined"
          value={orderType}
          onChange={(e) => setOrderType(e.target.value as string)}
          sx={{
            // !overridden the default height of selectField
            height: '24px',
            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            },
            padding: 0.5,
          }}
          MenuProps={MenuProps}>
          <OPSelectDropDownHeader type="tsOrdersType" />
          {orderTypes.map((item) => {
            return (
              <StyledDropdownItemWithBorder color="default" key={item} value={item}>
                {item}
              </StyledDropdownItemWithBorder>
            );
          })}
        </OPSelectField>
      </Grid>
    );
  };

  const updateFun = (type: 'limitPrice' | 'stopPrice', value: string) => {
    let clone = cloneDeep(commonOrderRequest);
    if (type === 'limitPrice') {
      clone.limitPrice = value;
    }
    if (type === 'stopPrice') {
      clone.stopPrice = value;
    }
    setCommonOrderRequest(clone);
  };

  const renderLimitPrice = () => {
    if (orderType === TSOrderType.StopMarket || orderType === TSOrderType.Market) {
      return;
    }
    return (
      <Grid item container xs={renderGridVal()}>
        <Grid item container xs={12}>
          <Typography variant="captionSmall">Limit Price</Typography>
          <Typography color={theme.palette.error.main} lineHeight={1.2}>
            *
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TSTextField
            variant="outlined"
            // type="number"
            value={commonOrderRequest.limitPrice}
            onChange={(event: { target: { value: string } }) => {
              updateFun('limitPrice', event.target.value);
            }}
            sx={{
              width: '100%',
              borderRadius: '8px',
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const renderStopPrice = () => {
    if (orderType === TSOrderType.Limit || orderType === TSOrderType.Market) {
      return;
    }
    return (
      <Grid item container xs={renderGridVal()}>
        <Grid item container xs={12}>
          <Typography variant="captionSmall">Stop Price</Typography>
          <Typography color={theme.palette.error.main} lineHeight={1.2}>
            *
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TSTextField
            variant="outlined"
            value={commonOrderRequest.stopPrice}
            type="number"
            onChange={(event: { target: { value: string } }) => {
              updateFun('stopPrice', event.target.value);
            }}
            sx={{
              width: '100%',
              borderRadius: '8px',
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const renderGridVal = () => {
    if (orderType === TSOrderType.Limit || orderType === TSOrderType.StopMarket) {
      return 6;
    }
    if (orderType === TSOrderType.StopLimit) {
      return 4;
    }
    return 12;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={renderGridVal()}>
        {renderOrderType()}
      </Grid>
      {renderLimitPrice()}
      {renderStopPrice()}
    </Grid>
  );
};
