import { debounce } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ICommonOrderRequest, ILimitOrderRequest, IPlaceOrderLeg, orderTypes } from '../models';
import { limitPlaceOrderForReview } from '../services';
import {
  tsAccountIdsSelectorState,
  tsEnvironmentState,
  tsOrderTicketState,
  tsSelectedAccountState,
} from '../states/trade-station-states';
import {
  commonOrderRequestState,
  confirmOrderTicketState,
  limitOrderRequestState,
  tsLimitPriceState,
  tsOrdetTicketPositionState,
} from '../states/ts-ticket-states';
import { CommonOrderTicketWidget } from './common-order-ticket-widget';
import { TSOrderType } from '@op/shared/src/models/enums/enums';

interface Props {
  setOrderType: (type: string) => void;
  orderLegs: IPlaceOrderLeg[];
}

export const LimitOrderTicketWidget: React.FC<Props> = ({ setOrderType, orderLegs }: Props) => {
  const orderType = TSOrderType.Limit;
  const selectedAccount = useRecoilValue(tsSelectedAccountState);
  const allAccounts = useRecoilValue(tsAccountIdsSelectorState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setLimitOrderRequest = useSetRecoilState(limitOrderRequestState);
  const setConfirmOrderTicketData = useSetRecoilState(confirmOrderTicketState);
  const [commonOrderRequest, setCommonOrderRequest] = useRecoilState(commonOrderRequestState);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const tsOrderTicketPositions = useRecoilValue(tsOrdetTicketPositionState);
  const setOrderTicketValue = useSetRecoilState(tsOrderTicketState);
  const tsLimitPrice = useRecoilValue(tsLimitPriceState);
  const resetTSLimitPrice = useResetRecoilState(tsLimitPriceState);

  useEffect(() => {
    // const req: ICommonOrderRequest = {
    //   ...commonOrderRequest,
    //   limitPrice: commonOrderRequest && commonOrderRequest.limitPrice ? commonOrderRequest.limitPrice : '',
    //   stopPrice: undefined,
    // };
    const req: ICommonOrderRequest = {
      ...commonOrderRequest,
      timeInForce: commonOrderRequest && commonOrderRequest.timeInForce ? commonOrderRequest.timeInForce : '',
      route: commonOrderRequest && commonOrderRequest.route ? commonOrderRequest.route : 'Intelligent',
      gtdTime: commonOrderRequest && commonOrderRequest.gtdTime ? commonOrderRequest.gtdTime : new Date(),
      limitPrice:
        tsLimitPrice !== ''
          ? tsLimitPrice
          : commonOrderRequest && commonOrderRequest.limitPrice
          ? commonOrderRequest.limitPrice
          : '',
      stopPrice: undefined,
    };
    setCommonOrderRequest(req);
    return () => {
      if (tsLimitPrice.length > 0) {
        resetTSLimitPrice();
      }
    };
  }, [tsOrderTicketPositions]);

  if (!tsOrderTicketPositions || !commonOrderRequest) {
    return <></>;
  }

  const limitOrderPreview = async (isBuy: boolean) => {
    if (!isBuy) {
      return;
    }
    setOrderTicketValue('isLoading');
    let request: ILimitOrderRequest = {
      accountID: selectedAccount[0].accountID,
      buyingPowerWarning: 'Enforce',
      orderType: orderType,
      timeInForce: commonOrderRequest.timeInForce,
      gtdTime: commonOrderRequest.gtdTime,
      route: commonOrderRequest.route,
      limitPrice: commonOrderRequest.limitPrice,
      legs: orderLegs,
    };
    setLimitOrderRequest(request);
    const response = await limitPlaceOrderForReview(tsEnv, request);
    if (response.hasErrors) {
      setNotifications([{ type: 'error', content: response.errors[0].message, isTradeStation: true }]);
      setOrderTicketValue('default');
      return;
    }
    setConfirmOrderTicketData(response.data);
    setOrderTicketValue('completed');
  };

  const debouncedlimitOrderPreview = debounce(limitOrderPreview, 1000);

  const disabledSendOrdersButton = () => {
    if (selectedAccount.length > 1) {
      return true;
    }
    if (commonOrderRequest.timeInForce === '' || commonOrderRequest.limitPrice === '') {
      return true;
    }
    return false;
  };

  return (
    <CommonOrderTicketWidget
      key={'limitOrder'}
      orderType={orderType}
      setOrderType={setOrderType}
      orderLegs={orderLegs}
      isDisabled={disabledSendOrdersButton()}
      onClickSendOrder={(v) => debouncedlimitOrderPreview(v)}
    />
  );
};
