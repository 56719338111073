import { ICallOptimal, IPutOptimal, PortfolioCombination, ProfitAndLossCalculator } from '@op/shared/src/models';
import { PorfolioAction, StrategyType, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import { PowerhouseProService, getQuotesAndSentiments } from '@op/shared/src/services';
import React, { useEffect } from 'react';
import {
  configurationState,
  expandedQuotesUpdater,
  isHubInitiatedState,
  previousSelectedSymbolState,
  strategyModifyState,
  tradeSimulatorOperationTypeState,
  tradingRangeSimulatorAtom,
} from '@op/shared/src/states';
import { howDataState, isDataLoadingState } from '@op/shared/src/states/data-states';
import {
  isSelectedRecommendedStateOpenState,
  portfolioManagementSelectedCombinationState,
  portfolioSelectedCombinationState,
  portfolioSelectedEntityState,
  rollOptimalState,
} from '@op/shared/src/states/portfolio-states';
import {
  plCalculatorState,
  portfolioManagementCombinationsUpdaterState,
  selectedRecommendationState,
} from '@op/shared/src/states/portfolio-management-states';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import HowDataModel from '@op/shared/src/models/how/how-data-model';
import { PortfolioAlertModel } from '@op/shared/src/models/portfolio/portfolio-alert-model';
import { PortfolioCombinationsOfAnAccount } from '@op/shared/src/models/portfolio/portfolio-combinations-of-an-account';
import { PortfolioManagementStrategiesWidget } from './portfolio-management-strategies-widget';
import { PortfolioPositionsManagement } from '@op/shared/src/models/portfolio/portfolio-positions-management';
import { Recommendation } from '@op/shared/src/models/portfolio/recommendation-model';
import { SentimentModel } from '@op/shared/src/hubs/sentiment-model';
import { TradingRangeSimulator } from '@op/shared/src/models/how/trading-range-simulator';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { sentimentsUpdater } from '@op/shared/src/states/sentiment-hub-states';
import { PortfolioManagementSkeletonWidget } from './portfolio-management-skeleton-widget';
import formatting from '@op/shared/src/models/how/formatting';

export const PortfolioManagementWidget: React.FC = () => {
  const isHubInitialized = useRecoilValue(isHubInitiatedState);
  const howData = useRecoilValue(howDataState);
  const configuration = useRecoilValue(configurationState);
  const isDataLoading = useRecoilValue(isDataLoadingState);
  const portfolioSelectedEntity = useRecoilValue(portfolioSelectedEntityState);
  const selectedRecommendation = useRecoilValue(selectedRecommendationState);
  const [portfolioSelectedCombination, setPortfolioSelectedCombination] = useRecoilState(
    portfolioSelectedCombinationState,
  );
  const setPreviousSelectedSymbol = useSetRecoilState(previousSelectedSymbolState('portfolioManagement'));
  const setRollOptimal = useSetRecoilState(rollOptimalState);
  const resetRollOptimal = useResetRecoilState(rollOptimalState);
  const setPortfolioManagementCombinationsUpdater = useSetRecoilState(portfolioManagementCombinationsUpdaterState);
  const setTradingRangeSimulator = useSetRecoilState(tradingRangeSimulatorAtom);
  const setPLCalculator = useSetRecoilState(plCalculatorState);
  const setPortfolioManagementSelectedCombination = useSetRecoilState(portfolioManagementSelectedCombinationState);
  const setTradeSimulator = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setStrategyModify = useSetRecoilState(strategyModifyState);
  const setNotifications = useSetRecoilState(notificationsState);
  const isSelectedRecommendedStateOpen = useRecoilValue(isSelectedRecommendedStateOpenState);
  const setExpandedQuotes = useSetRecoilState(expandedQuotesUpdater);
  const setSentiments = useSetRecoilState(sentimentsUpdater);

  useEffect(() => {
    if (!isHubInitialized || !portfolioSelectedEntity || isDataLoading || !howData) {
      return;
    }
    if (
      howData.symbol !== portfolioSelectedEntity.symbol &&
      formatting.symbolDotExchangeToSymbol(howData.symbol) !== portfolioSelectedEntity.symbol
    ) {
      return;
    }
    // TODO: Re do this condition for reset problem
    if (isSelectedRecommendedStateOpen) {
      return;
    }
    (async () => {
      const symbols = portfolioSelectedEntity.symbolsToSubscribe();
      const quotesAndSentiments = await getQuotesAndSentiments(symbols);
      if (!quotesAndSentiments) {
        setNotifications([{ type: 'error', content: 'Failed to fetch market data. Please try again.' }]);
        return;
      }
      //Note: This fills into recoil, which also updates the cache.
      setExpandedQuotes(quotesAndSentiments.quotes);
      setSentiments(quotesAndSentiments.sentiments.map((s) => SentimentModel.fromData(s)));
      const combination = PortfolioCombinationsOfAnAccount.createCombination(
        portfolioSelectedEntity,
        quotesAndSentiments.quotes,
        quotesAndSentiments.sentiments,
        quotesAndSentiments.quotes,
      );
      resetRollOptimal();
      const optimal = await getIncomeOptimals(combination);
      if (optimal) {
        setRollOptimal(optimal);
      }
      setPortfolioSelectedCombination(combination);
      initialize(combination, optimal);
    })();
  }, [isHubInitialized, portfolioSelectedEntity, isDataLoading]);

  const initialize = (
    portfolioSelectedCombination: PortfolioCombination,
    optimal: ICallOptimal | IPutOptimal | undefined,
  ) => {
    if (!howData) {
      return;
    }
    const strategies = selectedRecommendation
      ? getRecommendation(howData, portfolioSelectedCombination, selectedRecommendation, optimal) //PortfolioPositionsManagement.fromRecommendation(howData, portfolioSelectedCombination, selectedRecommendation)
      : PortfolioPositionsManagement.fromData(howData, portfolioSelectedCombination);
    const combinations = strategies.allCombinations;
    const tradingRangeSimulator = TradingRangeSimulator.fromData(howData, combinations);
    const plCalculator = ProfitAndLossCalculator.fromData(portfolioSelectedCombination);
    setPortfolioManagementCombinationsUpdater(combinations);
    if (selectedRecommendation) {
      setStrategyModify(combinations[1]); //recommendation combination
      setPortfolioManagementSelectedCombination(combinations[2]); // resultant combination
      setTradeSimulator(tradeSimulatorOperationType.Modify);
    }
    setTradingRangeSimulator(tradingRangeSimulator);
    setPLCalculator(plCalculator);
    setPreviousSelectedSymbol(portfolioSelectedCombination.hash);
  };

  const getRecommendation = (
    howData: HowDataModel,
    portfolioSelectedCombination: PortfolioCombination,
    selectedRecommendation: Recommendation,
    optimal: ICallOptimal | IPutOptimal | undefined,
  ) => {
    let recommendation = selectedRecommendation;
    if (recommendation.adjustmentText === 'Roll Position' && optimal) {
      const legs = PortfolioAlertModel.getRollPositionLegsForOptimalCall(
        howData,
        portfolioSelectedCombination,
        optimal,
      );
      recommendation = Recommendation.fromAdjustment(PorfolioAction.ROLL_POSITION, legs);
      recommendation.isAlert = true;
      // recommendation.isRoll = true;
    }
    return PortfolioPositionsManagement.fromRecommendation(howData, portfolioSelectedCombination, recommendation);
  };

  const getIncomeOptimals = async (portfolioSelectedCombination: PortfolioCombination) => {
    const template = portfolioSelectedCombination.matchedTemplate();
    let optimal: ICallOptimal | IPutOptimal | undefined;
    if (!template || !configuration) {
      return;
    }
    const legType =
      template.template.name === StrategyType.CoveredCall
        ? 'Call'
        : template.template.name === StrategyType.Put
        ? 'Put'
        : undefined;
    let timeFrame = '';
    let aggressiveness = '';
    if (!legType) {
      return;
    }
    if (legType === 'Call') {
      timeFrame = configuration.applicationConfiguration.coveredCall.call.timeFrame;
      aggressiveness = configuration.applicationConfiguration.coveredCall.call.aggressiveness;
    }
    if (legType === 'Put') {
      timeFrame = configuration.applicationConfiguration.coveredCall.put.timeFrame;
      aggressiveness = configuration.applicationConfiguration.coveredCall.put.aggressiveness;
    }
    const data = await PowerhouseProService.instance.getIncomeOptimals(
      portfolioSelectedCombination.symbol,
      timeFrame,
      aggressiveness,
      legType,
    );
    if (!data || data.hasErrors || !data.data) {
      return;
    }
    if (legType === 'Call' && data.data.callOptimals?.length > 0) {
      optimal = data.data.callOptimals[0] as ICallOptimal;
    }
    if (legType === 'Put' && data.data.putOptimals?.length > 0) {
      optimal = data.data.putOptimals[0] as IPutOptimal;
    }
    return optimal;
  };

  if (
    isDataLoading ||
    !portfolioSelectedCombination ||
    !portfolioSelectedEntity ||
    portfolioSelectedCombination?.hash !== portfolioSelectedEntity?.hash
  ) {
    return <PortfolioManagementSkeletonWidget />;
  }

  return <PortfolioManagementStrategiesWidget />;
};
